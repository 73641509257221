import * as Sentry from "@sentry/react";

//General purpose fetch  
const general_fetch = async ({ url, full_url, method, headers, body, is_form_data, credentials, data_field, req_resp, return_raw_response }) => {
	try {
		if (!data_field) {
			data_field = 'data';
		}
		if (full_url) {
			url = full_url;
		} else {
			url = global.config.server_path + url;
		}
		if (!url) {
			throw 'invalid url provided';
		}
		if(!method) {
			method = 'POST';
		}
		if (!is_form_data) {
			if (!headers) {
				headers = {
					'Content-Type': 'application/json'
				};
			}
			if (body) {
				body = JSON.stringify(body);
			}
		}
		if (!credentials) {
			credentials = 'include';
		}
		
        var options = { method, headers, credentials, body };
        //console.log("options======>" , options)
        var resp = await fetch(url, options);
        resp = await resp.json();
		if(req_resp || return_raw_response){
			return resp;
		}
        if (resp.response_code == 1 || resp.response_code == 0) {
        	return resp[data_field];
        }else {
        	throw new Error('Server call failed with error --' + resp.error); 
        }
	} catch(err) {
		if(resp && resp.status===401){
			global.config.check_401(true)
		}else{
			if(err.constructor.name.includes("Error") != true) err=new Error(err)
			err.name = "Captured error in the API call " + url + (body ? " with body "+ JSON.stringify(body) : '') + " : " + err.name
			console.error(err);
			Sentry.captureException(err);
		}
		return Promise.reject({ err, resp,info: 'Error in general_fetch' })
	}
} 

window.general_fetch = general_fetch;
export default general_fetch ;
