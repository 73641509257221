import { useEffect, useState } from 'react';

function GoogleAuth({ target, id, visible, google, authorization_callback }) {
    const [ initialized, set_initialized ] = useState(false);
    // const googleButton = useRef(null);
    useEffect(() => {
        if (google) {
            console.log('inside s-login script loaded', id)
            google.accounts.id.initialize({
                client_id: "172166388248-8i8btuhr2ga4ti4eu200j9gt04ppuavp.apps.googleusercontent.com",
                callback: authorization_callback,
                // ux_mode: "redirect",
                // login_uri: target == 'admin' ? "https://stagebackend.infurnia.com/social_login_google/stageadmin" : "https://stagebackend.infurnia.com/social_login_google/stagedesign",
                itp_support: true
            })
            if (visible && !initialized) {
                console.log('inside s-login visible', id)
                google.accounts.id.renderButton(
                    // googleButton.current, //this is a ref hook to the div in the official example
                    document.getElementById(id),
                    {
                        logo_alignment:'left', 
                        type:'standard', 
                        text: 'continue_with', 
                        theme:'outline', 
                        size:'large',
                        width: 199,
                    } // customization attributes
                )
                google.accounts.id.prompt();
                set_initialized(true);
            }
        }
        if (!visible) {
            console.log("inside invisible s-login", id);
            set_initialized(false);
        }
    }, [ google, visible ])

    return <div id={id} style={{display:'flex', alignItems:'center', justifyContent:'center'}}></div> 
} 

export default GoogleAuth