var config_settings = {
	"production":{
		server_path: 'https://productionbackend.infurnia.com',
		render_server: 'http://localhost:6165/',
		admin_path: 'https://admin.infurnia.com/',
		auth_redirect_path:'https://localhost:3000/login/',
		application_env: 'production',
		affilate_path: 'https://affiliate.infurnia.com',
		design_path:'https://design.infurnia.com',
		sentry_environment: 'production'
	},
	"preprod":{
		server_path: 'https://preprodbackend.infurnia.com',
		render_server: 'http://localhost:6165/',
		admin_path: 'https://preprodadmin.infurnia.com/',
		auth_redirect_path:'https://localhost:3000/login/',
		application_env: 'preprod',
		affilate_path: 'https://preprodaffiliate.infurnia.com',
		design_path:'https://preproddesign.infurnia.com',
		sentry_environment: 'preprod',
	},
	"staging":{
		server_path: 'https://stage1backend.infurnia.com',
		render_server: 'http://localhost:6165/',
		admin_path: 'https://stage1admin.infurnia.com/',
		auth_redirect_path:'https://localhost:3000/login/',
		application_env: 'stage',
		affilate_path: 'https://stagingaffiliate.infurnia.com',
		design_path:'https://stage1design.infurnia.com',
		sentry_environment: 'stage',
	},
	"dev":{
		server_path: 'https://stagebackend.infurnia.com',
		render_server: 'http://localhost:6165/',
		admin_path: 'https://stageadmin.infurnia.com/',
		auth_redirect_path:'https://localhost:3000/login/',
		application_env: 'stage',
		affilate_path: 'https://stagingaffiliate.infurnia.com',
		design_path:'https://stagedesign.infurnia.com',
		sentry_environment: 'dev',
	}
}

var config = config_settings[process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'staging']
global.config = config
//https://stagingbackend.infurnia.com
// server_path: 'https://newdevbackend.infurnia.com/',
// 		render_server: 'http://localhost:6165/',
// 		admin_path: 'https://stagingadmin.infurnia.com/',
// 		auth_redirect_path:'http://localhost:3000',
// 		application_env: 'dev',
// 		affilate_path: 'https://stagingaffiliate.infurnia.com',
