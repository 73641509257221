import React, { useState, useEffect } from 'react';
import { Router, Route, Switch, useHistory} from 'react-router-dom';
import { Table, Col, Row, Card, CardBody, CardHeader, Button, CardColumns, CardText } from "reactstrap";
import { COLORS } from './utils/color';
import useStateCallback from './utils/useStateCallback.js';
import Sign_up from "./Sign_up/";
import ResetPassword from "./ResetPassword/";
import { useAlert } from 'react-alert'
// import DeleteNotification from "./components/DeleteNotification/";
// import { confirmAlert } from 'react-confirm-alert'; 
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import general_fetch from './utils/fetch';
// import 'react-confirm-alert/src/react-confirm-alert.css';
import './App.css';
// import SignIn from './SignIn';



var version_save_underprocess_timer;

const styles = ({
    
        filler_table_header_style:{
            color:'white', 
            fontSize:'14px'
        },
        filler_table_style:{
            backgroundColor:"black",
            border:'0px',
        }
    
})

// const unauthenticated_routes=[
//     '/login/','/login',
//     '/login/admin/','/login/admin',
//     '/login/affiliate/','/login/affiliate',
//     '/login/social_signup/','/login/social_signup',
//     '/reset_password','/reset_password/'
// ]

const unauthenticated_routes=[
    '/','',
    '/admin/','/admin',
    '/affiliate/','/affiliate',
    '/social_signup/','/social_signup',
    '/reset_password','/reset_password/'
]

const Filler = ({ initialized, message}) => (
    <Table className="align-items-center table-flush text-center" responsive>
        <thead className="text-center">
            <tr>
                { initialized ? (
                    <th scope="col" style={styles.filler_table_style}>
                        <span style={styles.filler_table_header_style}>No data to show.</span><br/> <span style={styles.filler_table_header_style}>{message?message:''}</span> <br /> &nbsp;
                    </th>
                ):(
                    <th scope="col">
                        <Row>
                            <Col xs={12} className="text-center">
                                <span style={styles.filler_table_header_style}><b>Fetching data...</b></span><br/> <br /> Please wait...<br /> <br />
                            </Col>
                        </Row>
                    </th>
                )}
            </tr>
        </thead>
    </Table>	
);

const App = () => {
    const alert = useAlert();
    // const [ user, set_user ] = useState('');
    const [ store_details, set_store_details ] = useState({});
    const [ user_loaded, set_user_loaded ] = useState(false);
    const [ init_needed, set_init_needed ] = useState(false);
    const [ remote_view, set_remote_view ] = useState(true); 
    const [ version_outdated, set_version_outdated ] = useState(false);
    const [ device_isnt_supported, set_device_isnt_supported ] = useState(false); 
    const [ login_modal_type, set_login_modal_type ] = useState('normal') //todo

    const history = useHistory();

    const [org_key_logo, set_org_key_logo] = useState("");
    const [org_key_id, set_org_key_id] = useState("");
    const [org_key_name, set_org_key_name] = useState("");

    const all_legal_routes_initial = ["","login","project","design","share","seller","reset_password"];

    const react_version = "171";

    const [page_loader,set_page_loader] = useStateCallback({
        show: true,
        text: "Please wait while we set up everything..."
    });

    const get_org_key = () => {
        var initial_key = window.location.pathname.split('/')[1]
        if(all_legal_routes_initial.includes(initial_key)){
            return "";
        }else{
            return initial_key;
        }
    }

    const init = async () => {
        // var is_remote_view = window.location.href.includes('/share/');
        var is_seller_specific = window.location.href.includes('/seller/');
        var target_is_admin = window.location.href.includes('/admin/');
        
        var response = "";
        try{
            // TODO make sure response gets correct value
            response = await general_fetch({url:'/user/get_details'});
        }catch(err){
            console.log(err);
            response = "error";
            // return Promise.reject(err);
        }

        // if(is_remote_view && (response === "unauthorized" || response === "user_not_designer" || response === "error")){
        //     var split = window.location.pathname.split('/');
        //     var branch_id = split[split.length-1];

        //     await window.Promisify(window.Module.view_login(branch_id));
        //     response = await general_fetch({url:'/user/get_details'});
        //     if(response === "unauthorized" || response === "error"){
        //         // alert.error("Link sharing is disabled for the current design",{timeout:20000});
        //         set_page_loader({show: false,text: "Please wait..."});
        //         return Promise.resolve(response)
        //     }else if(response == "user_not_designer"){
        //         //ignoring user roles in remote view
        //         response = "success";
        //     }

        // }


        //console.log(response);
        var org_key = get_org_key()
        console.log("org_key", org_key);

        //TODO
        // if(response && response["user"] && response["user"]["user_details"]["orphan"] && response["user"]["user_details"]["orphan"]==1){
        //     set_init_needed(true);
        //     // var url = "/login/social_signup";
        //     // let t = (org_key?(url = "/"+org_key + url):null);
        //     // history.push(url)
        //     set_login_modal_type('already_signed_user')
        //     return Promise.resolve("needs_signup");
        // }else if(response === "unauthorized" || response === "user_not_designer" || response === "error"){
        //     if(!(response === "user_not_designer" && (target_is_admin || is_seller_specific))){
        //         set_init_needed(true);
        //         var url = "/login";
        //         let t = (org_key?(url = "/"+org_key + url):null);
        //         history.push(url)
        //         if(response === "user_not_designer"){
        //             // alert.error("You do not have designer access, Please contact your administrator")
        //         }
        //     }

        //     return Promise.resolve(response);
        // }

        // if(org_key && org_key_id){
        //     //extra check to ensure signed in user belongs to the right store
        //     var current_store_id = window.Module.get_active_user().store_id;

        //     if(org_key_id != current_store_id){
        //         set_init_needed(true);
        //         var url = "/login";
        //         let t = (org_key?(url = "/"+org_key + url):null);
        //         history.push(url)

        //         return Promise.resolve(response);
        //     }
        // }
 

        // try{
        //     if(!window.location.origin.includes('localhost') && !window.location.origin.includes('admin_local_dev') && !window.location.origin.includes('devdesign')){
        //         Sentry.configureScope(function(scope) {
        //             // scope.setTag("my-tag", "my value");
        //             scope.setUser({
        //                 id: window.Module.t().designer_id,
        //                 email: window.Module.get_active_user().designer_email
        //             });
                    
        //             scope.setTag("org_name", window.Module.get_active_user().store_name);
        //             scope.setTag("org_id", window.Module.get_active_user().store_id);
        //             scope.setTag("react_version", react_version);
        //             scope.setTag("core_version", window.Module.get_infurnia_version_core());
        //         });
        //     }
        //     // set_store_details(window.Module.get_active_user())
        // }catch(err){
        //     console.error(err);
        // }

        // set_remote_view(is_remote_view);
        //console.log(remote_view)

        // if(!is_remote_view){
        //     // await window.Promisify(window.Module.resource_init());
            
        //     if(window['analytics']){
        //         try{
        //             var user = window.Module.get_active_user()
        //             // console.log(user)
        //             window.analytics.identify(user.designer_id, {
        //                 name: user.designer_name,
        //                 email: user.designer_email,
        //                 company: {
        //                     id: user.store_id,
        //                     name:user.store_name,
        //                 },
        //                 createdAt: user.created_at
        //             });
        //         }catch(err){
        //             console.log(err);
        //         }
        //     }
        // }

        // var response_obj = response && JSON.parse(response)  
        // set_user(response_obj && response_obj.user && response_obj.user.user_details ?(response_obj.user.user_details) :'none')  

        // try{
        //     //disconnecting existing socket connection if present
        //     if(window.socket){
        //         window.socket.disconnect();
        //     }
        // }catch(err){
        //     console.log(err);
        // }

        // window.socket = window.io.connect(global.config.server_path,{transports:['websocket']});
        // window.socket.on('connect',function(data) {
        //     window.Module.Socket.on('connect',"\"\"");
        //     console.log("socket connected at "+performance.now());
        // });
        // window.socket.on('disconnect',function(data) {
        //     window.Module.Socket.on('disconnect',"\"\"");
        //     console.log("socket disconnected at "+performance.now());
        // });

        // window.socket.on('please_logout',function(data) {
        //     if(window.socket){
        //         window.socket.disconnect();
        //     }
        //     var url = "/login?e=multilogin";
        //     let t = (get_org_key()?(url = "/"+get_org_key() + url):null);
        //     history.push(url)
        // });

        // window.socket.on('please_refresh',function(data) {
        //     window.location.reload();
        // });

        // window.socket.on('version_updated',function(data){
        //     // set_version_outdated(true);
        // })

        // var onevent = window.socket.onevent;
        // window.socket.onevent = function (packet) {
        //     var args = packet.data || [];
        //     onevent.call (this, packet);    // original call
        //     packet.data = ["*"].concat(args);
        //     onevent.call(this, packet);      // additional call to catch-all
        // };
        // window.socket.on('*',function(event,data) {
        //     var data_stringified;
        //     if(data){
        //         data_stringified = JSON.stringify(data);
        //     }else{
        //         data_stringified = '""';
        //     }
        //     window.Module.Socket.on(event,data_stringified);

        //     if(window.debug_mode && event == "save_response"){
        //         console.log(data)
        //         // alert.info((data.response_code == 1?"successfully saved: ":"failed to save: ") + data.data.design_version.name);
        //     }

        //     if(event == "data_update"){
        //         set_page_loader({show: false,text: "Please wait..."})
        //     }else if(event == "save_response" && data.response_code == 1){
        //         clearTimeout(version_save_underprocess_timer);
        //     }
        // });

        // window.socket.on('close',function(data) {
        //     console.log("socket connection was closed. reopening it");
        //     window.socket.open();
        // });

        // window.wrapper_socket_emit = async (message, data) => {
        //     if(message == "restore_version_with_components"){
        //         set_page_loader({show: true,text: "Please wait..."})
        //     }else if(message == "save"){
        //         if(window.debug_mode){
        //             // alert.info("trying to save: "+data.name);
        //         }

        //         clearTimeout(version_save_underprocess_timer)

        //         version_save_underprocess_timer = setTimeout(function() {
        //             console.error("Design Changes are not getting saved.. Reloading to last saved state in 3 seconds.");
        //             set_page_loader({show: true,text: "Design Changes are not getting saved.. Reloading to last saved state in 3 seconds."});
                    
        //             try{
        //                 throw "Design Changes are not getting saved.. Reloading to last saved state in 3 seconds."
        //             }catch(err){
        //                 console.error(err);
        //                 setTimeout(() => {
        //                     unhook("version save timed out");
        //                     window.location.reload();
        //                 },3000)
        //             }
        //         }, 50000)
        //     }

        //     window.socket.emit(message, data);
        // }



        // window.onbeforeunload = function (e) {
        //     if (window.Module.get_version_save_queue_length() > 0 && !window["ignore_onbeforeunload"]) {
        //         e = e || window.event;
    
        //         // For IE and Firefox prior to version 4
        //         if (e) {
        //             e.returnValue = 'You might loose some unsaved changes. Do you want to quit ?';
        //         }
    
        //         // For Safari
        //         return 'You might loose some unsaved changes. Do you want to quit ?';
        //     }
        // };

        // window.removeEventListener('online',  update_online_status);
        // window.removeEventListener('offline', update_online_status);

        // window.addEventListener('online',  update_online_status);
        // window.addEventListener('offline', update_online_status);

        // if(window.socketVersionLoop){
        //     clearInterval(window.socketVersionLoop)
        // }

        // window.socketVersionLoop = setInterval(function(){
        //     if(window.socket){
        //         window.socket.emit('version_check',{
        //             frontend_version: react_version,
        //             core_version: window.Module.get_infurnia_version_core()
        //         });
        //     }
        // },5000);

        // return new Promise((res, rej) => {
        //     window.socket.once('connect',function(data) {
        //         res();
        //     });
        // });
    }

    // const unhook = (unhook_info) => {
    //     if(window['analytics']){
    //         try{
    //             window.analytics.track('logout', {
    //                 "category": "unhook",
    //                 "label": unhook_info,
    //                 "value": 1
    //             });
    //         }catch(err){
    //             console.log(err);
    //         }
    //     }
    // }

    // const wait_for_version_save_queue = () => {
	// 	return new Promise(function(resolve,reject){
	// 		var length_at_start_of_wait = window.Module.get_version_save_queue_length();
	// 		if(length_at_start_of_wait > 0){
	// 			set_page_loader({show: true,text: "Saving your design. Do not Refresh. Progress - 0/" + length_at_start_of_wait});

	// 			var interval = setInterval(function(){
	// 			    set_page_loader({show: true,text: "Saving your design. Do not Refresh. Progress - "+ (length_at_start_of_wait - window.Module.get_version_save_queue_length()) +"/" + length_at_start_of_wait});

	// 				if(window.Module.get_version_save_queue_length() == 0){
	// 			        set_page_loader({show: false,text: "Please wait..."});
	// 					clearInterval(interval)
	// 					resolve()
	// 				}
	// 			},100)
	// 		}else{
	// 			resolve()
	// 		}
	// 	})
	// }

    // const update_online_status = (e) => {
    //     if (window.event.type == 'offline') {
    //         try {
    //             set_page_loader({show: true,text: "You are offline. Please check your internet connection."});
    //         }
    //         catch(err) {
    //             console.log("error from app js; set page loader not showing!")
    //         }
    //     }
    //     else if (window.event.type == 'online') {
    //         try{
    //             set_page_loader({show: false,text: "Please wait..."});
    //             // alert.success("You are now online");
    //         }
    //         catch(err) {
    //             console.log("error from app js; set page loader not showing!")
    //         }
    //     }
    // }

    // const submit_for_delete_confirm = (yes_del, no_del, message, nonowned) => {
    //     confirmAlert({
    //       title: 'Confirm to submit',
    //       message: message,
    //       customUI: ({ onClose }) => {
    //         return (
    //             <div className='custom-ui'>
    //                 <DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} nonowned={nonowned}/>
    //             </div>
    //         );
    //       }
    //     });
    // };
    
    const isMobile = () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
        
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    const isChromium = () => {
        return window['chrome'] != undefined
    }

    // useEffect(() => {
    //     // window.set_page_loader = set_page_loader;
    //     if(isMobile() || !window['SharedArrayBuffer'] || !isChromium()){
    //         set_page_loader({
    //             show: false,
    //             text: "Please wait..."
    //         })
    //         set_device_isnt_supported(true);
    //     }else{
    //         window.wasm_loaded_promise.then(async () => {
    //             set_page_loader({
    //                 show: true,
    //                 text: "Fetching resources..."
    //             },async () => {
    //                 //await dummy_tick(0);
    //                 if(!window.location.origin.includes('localhost') && !window.location.origin.includes('admin_local_dev') && !window.location.origin.includes('devdesign')){
    //                     Sentry.init({
    //                         dsn: "https://b5631d5055c24269b02efbe048b73721@o932499.ingest.sentry.io/6212122",
    //                         environment: global.config.application_env,
    //                         integrations: [new BrowserTracing()],
    //                         // We recommend adjusting this value in production, or using tracesSampler
    //                         // for finer control
    //                         tracesSampleRate: 1.0,
    //                         debug: global.config.application_env=="production"?false:true
    //                     });
    //                 }

    //                 console.log("Infurnia version " + react_version);
    
    //                 window['out'] = console.log.bind(console);
    //                 window['err'] = console.error.bind(console);
    
    //                 await set_org_details();
                    
    //                 var init_resp = "";
    //                 if(!unauthenticated_routes.find(o => window.location.pathname.includes(o))){
    //                     try{
    //                         init_resp = await init();
    //                     }catch(err){
    //                         console.log(err)
    //                     }
    //                 }else{
    //                     set_init_needed(true);
    //                 }
    
    //                 set_page_loader({
    //                     show: false,
    //                     text: "Please wait..."
    //                 },async () => {
    //                     set_user_loaded(true);
    //                 });
    //             })
    //         })
    //     }

    // }, []);

    useEffect(() => {
        (async () => {
            
            await set_org_details()

            var init_resp = "";
            if(!unauthenticated_routes.find(o => window.location.pathname.includes(o))){
                try{
                    init_resp = await init();
                }catch(err){
                    console.log(err)
                }
            }else{
                set_init_needed(true);
            }

            set_page_loader({
                show: false,
                text: "Please wait..."
            },async () => {
                set_user_loaded(true);
            });
        })()
    }, []);

    const set_org_details = async () => {
        var org_key = get_org_key()

        if(org_key){
            var resp_store_white_labelling = await general_fetch({url:'/store/get_white_labelling_details', body:{white_labelling_url:org_key}})
            if(resp_store_white_labelling){
                set_org_key_id(resp_store_white_labelling.id);
                set_org_key_logo(global.config.server_path + "/" + resp_store_white_labelling.logo_path);
                set_org_key_name(resp_store_white_labelling.name);
            }else{
                alert.error('Invalid Organization URL');
                history.push('/')
                // set_org_key_logo('/infurnia.png')
            }
        }else{
            set_org_key_logo('/infurnia.png')
        }
    }

    const design_revert = async () => {
        console.log('Undo Last Action Button working!')
        var action_response = await window.Promisify(window.Module.design_revert());

        window.location.reload();

		//handle_ui_response(ui_response);
		//update_view();
    }

    
    return (<div className="App">
        {page_loader && page_loader.show?(
            <div style={{position:'fixed',backgroundColor:'rgba(20,20,20,0.7',left:0,top:0,width:'100%',height:'100%',zIndex:10000}}>
                {page_loader.text !== 'design_fail' ?
                (<div style={{position:'absolute',width:'350px',height:'40px',left:'calc(50% - 175px)',top:'calc(50% - 20px)',backgroundColor:'white',color:'#000',display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'2px'}}>
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; &nbsp; 
                    {/* <div class="loader">
                        <div class="cube"></div>
                        <div class="cube"></div>
                        <div class="cube"></div>
                    </div> &nbsp; &nbsp;  */}
                    <div>{page_loader.text}</div>
                </div>):
                (<Card className="bg-secondary shadow border-0" style={{width: "500px",height: "200px",position: "fixed",left: "calc(50vw - 250px)",top: "calc(50vh - 100px)"}}>
                <CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.white, fontSize:'16px', display:'flex',justifyContent:'space-between', alignItems:'center', fontWeight:600}} >	
                    <span>Something went wrong</span>
                </CardHeader>
                <CardBody style = {{backgroundColor: COLORS.white, maxHeight:'500px', overflow:'auto', padding:'10px 20px 20px'}}>
                    <CardText>An error occured while loading your design. We have sent the error log to our technical team. Until then you can try reverting the design one action back </CardText>
                    <CardText><Button onClick={design_revert} disabled={false} style={{height:'30px'}} className='blue_button'>Revert</Button></CardText>
                </CardBody>
            </Card>)}
            </div>
        ):''}

        {version_outdated?(
            <div style={{position:'fixed',backgroundColor:'rgba(20,20,20,0.7',left:0,top:0,width:'100%',height:'100%',zIndex:10000}}>
                <div style={{position:'absolute',width:'380px',height:'50px',left:'calc(50% - 180px)',top:'calc(50% - 25px)',backgroundColor:'#314456',color:'#ffffff',display:'flex', alignItems:'center', justifyContent:'center', borderRadius:'5px'}}>
                    <span>Our software on your browser is not up to date, Please refresh the page to get the latest update  </span>
                </div>
            </div>
        ):('')}
        {user_loaded?(
            <div>
                <Router history={history}>
                    <Switch>
                      <Route exact path={["/:org_key/reset_password/:reset_password_token", "/reset_password/:reset_password_token"]} render={({match}) => <ResetPassword org_key={match.params.org_key} set_page_loader={set_page_loader} reset_password_token={match.params.reset_password_token} org_key_id={org_key_id} org_key_logo={org_key_logo} org_key_name={org_key_name} />}/>
                      {/* <Route exact path={["/:org_key/login/:target?", "/login/:target?"]} render={({match}) => (<SignIn org_key={match.params.org_key} target={match.params.target} init={init} init_needed={init_needed} set_init_needed={set_init_needed} set_page_loader={set_page_loader} org_key_id={org_key_id} org_key_logo={org_key_logo} org_key_name={org_key_name} login_modal_type={login_modal_type} set_login_modal_type={set_login_modal_type}/>) } /> */}
                      <Route exact path={["/:org_key?"]} render={({match}) => (<Sign_up org_key={match.params.org_key} init={init} init_needed={init_needed} set_init_needed={set_init_needed} set_page_loader={set_page_loader} org_key_id={org_key_id} org_key_logo={org_key_logo} org_key_name={org_key_name} login_modal_type={login_modal_type} set_login_modal_type={set_login_modal_type}/>) } />
                      <Route render={() => (<div>Not Found</div>)} />
                    </Switch>
                </Router>
            </div>
            ):( 
                <React.Fragment>
                    <img alt='Not Available' src="/resources/images/site_image.jpeg" style={{position:'fixed',left:0,top:0,width:'100%',height:'100%'}} />
                    {device_isnt_supported?(
                        <Card className="bg-secondary shadow border-0" style={{width: "500px",height: "200px",position: "fixed",left: "calc(50vw - 250px)",top: "calc(50vh - 100px)"}}>
                            <CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.white, fontSize:'16px', display:'flex',justifyContent:'space-between', alignItems:'center', fontWeight:600}} >	
                                <span>Infurnia isn't supported on your device currently</span>
                            </CardHeader>
                            <CardBody style = {{backgroundColor: COLORS.white, maxHeight:'500px', overflow:'auto', padding:'10px 20px 20px'}}>
                                We are working on bringing support to more devices and browsers.  <br /><br /> Till then, we suggest using Google Chrome on windows/macOS/linux to get the best experience
                            </CardBody>
                        </Card>
                    ):''}
                </React.Fragment>
            )
        }
    </div>)
    
    
}    

export default App;

//<Route exact path="/home" render={() => (<Home user = {user.user_details} />)}/><Redirect to='/Login'/>